<template>
    <div></div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user-store';
const route = useRoute();
const userStore = useUserStore();
onMounted(() => {
    if (route.params) {
        if (route.params.token) {
            userStore.forceLogin(route.params.token);
        }
    }
});
</script>
